
import { Options, Vue } from "vue-class-component";
import { mapState,mapActions } from "vuex";
import Toast from 'primevue/toast';
import Notification from "@/types/notification";

@Options({
  components: {
      Toast
  },
  computed: {
    ...mapState("notification", ["notifications"]),
    notificationItems() {
      const toastItems = this.notifications.forEach((element: any) => {
        this.$toast.add({
          severity: element.type, 
          summary: element.message, 
          id: element.id,
          life: 5000
        })
        this.remove(element);
      });
      return toastItems
    },
  },
    methods: {
    ...mapActions({
      remove: "notification/remove",
    }),
  },
})
export default class NotificationCard extends Vue {
  public notification!: Notification;
}
